import { Link } from "react-router-dom";

export default function About(){
    return (
        <main className="main">
        
		{/* <!-- Start Breadcrumb
		============================================= --> */}
		<div className="site-breadcrumb" style={{backgroundImage: `url("assets/img/pictures/breadcrumb.jpg")`}}>
			<div className="container">
				<div className="site-breadcrumb-wpr">
					<h2 className="breadcrumb-title">About Us</h2>
					<ul className="breadcrumb-menu clearfix">
						<li><Link to="/">Home</Link></li>
						<li className="active">About Us</li>
					</ul>
				</div>
			</div>
		</div>
        {/* <!-- End Breadcrumb --> */}
        
		{/* <!-- Start About
		============================================= --> */}
		<div className="about-area-2 de-padding">
			<div className="container">
				<div className="about-wpr-2 grid-2">
					<div className="about-left-2">
						<div className="about-left-img">
							<div className="about-phase grid-2">
								<div className="about-phase-1">
									<img src="assets/img/vector/about-2.png" alt="no" className="about-left-img-main mb-30" />
									<img src="assets/img/vector/about-3.png" alt="no" className="about-left-img-main" />
								</div>
								<div className="about-phase-2 element-center">
									<img src="assets/img/vector/about-1.png" alt="no" className="about-left-img-main" />
								</div>
							</div>
							<img src="assets/img/vector/about-shape.png" alt="no" className="about-left-img-shape" />
						</div>
					</div>
					<div className="about-right-2 pl-30">
						<span className="hero-sub-title mb-20">
							<span className="hero-line"></span>
							About Company
						</span>
						<h2 className="heading-1 mb-30">
							Digital &amp; trusted transport logistic company
						</h2>
						<p className="mb-50">
						Al Zayed has been established in year 2000, The pioneer in the lost and found baggage delivery
							company. We provide a unique combination of on demand, time-sensitive, door to-door delivery
							services within the UAE, We make sure that the passenger’s luggage, is delivered safely and
							on-time.
						</p>
						<div className="about-opt-2">
							<div className="about-opt-left-2">
								<p>
									Our Staff
									Completes
									A Project
									Very Quickly.
								</p>
								<Link to="/about" className="btn-3">Read More</Link>
							</div>
							<div className="about-opt-right-2">
								<div className="about-opt-right-box-2 about-opt-right-pd">
									<div className="about-opt-icon-2">
										<i>
											<img src="assets/img/icon/delivered.png" alt="no" />
										</i>
									</div>
									<div className="about-opt-desc-2">
										<h2 className="heading-1">1959+</h2>
										<h5 className="heading-5 mb-0">
											Delivery Successful
										</h5>
									</div>
								</div>
								<div className="about-opt-right-box-2 about-opt-right-pd">
									<div className="about-opt-icon-2">
										<i>
											<img src="assets/img/icon/satisfaction.png" alt="no" />
										</i>
									</div>
									<div className="about-opt-desc-2">
										<h2 className="heading-1">100%</h2>
										<h5 className="heading-5 mb-0">
											Clients Satisfaction
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End About --> */}
		
		{/* <!-- Start Service
		============================================= --> */}
		<div className="service-area-2 bg-2 de-padding pos-rel">
			<img src="assets/img/shape/service-shape.png" className="service-shape-2" alt="no" />
			<div className="container">
				<div className="row">
					<div className="col-xl-8">
						<div className="site-title">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								0ur services
							</span>
							<h2 className="heading-1 mb-0">
								Specialist logistics <br /> services
							</h2>
						</div>
					</div>
					<div className="col-xl-4">
						<p className="mb-30">
						Our service gives you the power and flexibility to effectively manage all your deliveries.
						Al Zayed provides its services all around UAE via its land routes associates at prime gateways
						</p>
						<Link to="/contact" className="btn-1 btn-md">
							Discover More
						</Link>
					</div>
				</div>
				<div className="service-wpr-2 grid-3">
					<div className="service-box-2">
						<div className="service-pic-2">
							<img src="assets/img/pictures/service-1.jpg" alt="no" />
							<div className="service-desc-2">
								<div className="service-icon-title">
									<div className="service-icon-2">
										<i>
											<img src="assets/img/icon/cargo-ship.png" alt="no" />
										</i>
									</div>
									<div className="service-title-2">
										<h5 className="heading-5 mb-0">
											Clients At Abu Dhabi Airport
										</h5>
									</div>
								</div>
								<div className="service-text">
								<p>
									<ul>
										<li>Egypt Airways</li>
										<li>Qatar Airways</li>
									</ul>
									</p>
									<Link to="/client" className="service-btn-2">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="service-box-2">
						<div className="service-pic-2">
							<img src="assets/img/pictures/service-2.jpg" alt="no" />
							<div className="service-desc-2">
								<div className="service-icon-title">
									<div className="service-icon-2">
										<i>
											<img src="assets/img/icon/Icon-plane.png" alt="no" />
										</i>
									</div>
									<div className="service-title-2">
										<h5 className="heading-5 mb-0">
										Clients At Sharjah Airport
										</h5>
									</div>
								</div>
								<div className="service-text">
								<p>
									<ul>
										<li>Turkish	 Airlines</li>
										<li>Qatar Airways</li>
									</ul>
									</p>
									<Link to="/client" className="service-btn-2">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="service-box-2">
						<div className="service-pic-2">
							<img src="assets/img/pictures/service-3.jpg" alt="no" />
							<div className="service-desc-2">
								<div className="service-icon-title">
									<div className="service-icon-2">
										<i>
											<img src="assets/img/icon/Icon-home.png" alt="no" />
										</i>
									</div>
									<div className="service-title-2">
										<h5 className="heading-5 mb-0">
										Clients At Dubai Airport
										</h5>
									</div>
								</div>
								<div className="service-text">
									<p>
									<ul>
										<li>Oman Airways</li>
										<li>British Airways</li>
									</ul>
									</p>
									<Link to="/client" className="service-btn-2">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Service --> */}
		
		{/* <!-- Start CTA
		============================================= --> */}
		<div className="cta-area de-padding hero-bg" style={{backgroundImage: `url("assets/img/bg/bg-cta.jpg")`}}>
			<div className="container">
				<div className="row">
					<div className="col-xl-6 offset-xl-3">
						<div className="cta-wpr text-center">
							<div className="pl mb-50">
								<Link to="#" className="item popup-youtube play-bt">
									<i className="ti-control-play"></i>
								</Link>
							</div>
							<h2 className="heading-1 mb-50">
								New to logistics services? Need help?
							</h2>
							<div className="cta-bt">
								<Link to="/contact" className="btn-4 btn-md">
									Get a quote
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End CTA --> */}
		
		{/* <!-- Start Team
		============================================= --> */}
		<div className="team-area de-padding">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="site-title text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								Our Team
							</span>
							<h2 className="heading-1 mb-20">
								Our experienced experts
							</h2>
							<p className="mb-0">
							For the last 20 years almost , we are offering the best luggage
							delivery services with the help of our professional drivers.
							Al Zayed has employed 8 regular visa holders, which is the highest
							number as compared with other competitors.
							</p>
						</div>
					</div>
				</div>
				<div className="team-wpr grid-4">
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr.Talha Athar</h4>
							<span>Truck driver</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-3.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr. Athar Hussain</h4>
							<span>Manager at Dubai Airport</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-4.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr. Naseem Baig</h4>
							<span>Manager at Sharjah Airport</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-3.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr. Muhammad Asif</h4>
							<span>Manager at AbuDhabi Airport</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-4.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Team --> */}
		
	</main>	
    )
}
// import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Home(){
	// useEffect(() => {
	// 	const script = document.createElement('script');
	// 	script.src = "../../ /assets/js/swiper-bundle.min.js";
	// 	script.async = true;
	
	// 	document.body.appendChild(script);
	//   }, []);


    return (
        <main className="main">
		
		{/* <!-- Start Slider
		============================================= --> */}
		<div className="hero-area pos-rel bg bg-bottom-right home-2" style={{backgroundImage: `url("assets/img/vector/jahaj-4.png")`}}>
			<div className="hero-2-shapes">
				<img src="assets/img/shape/dag.png" className="hero-dag" alt="no" />
				<img src="assets/img/shape/dot-circle.png" className="hero-dot-circle" alt="no" />
				<img src="assets/img/shape/half-circle-2.png" className="half-circle-2" alt="no" />
				<img src="assets/img/shape/half-circle-1.png" className="half-circle-1" alt="no" />
				<img src="assets/img/shape/half-circle-3.png" className="hero-half-circle-3" alt="no" />
				<img src="assets/img/shape/shape-bottom.png"  className="hero-shape-bottom" alt="no" />
			</div>
			<div className="hero-single">
				<div className="container">
					<div className="hero-wpr pos-rel">
						<div className="row g-5">
							<div className="col-xl-8">
								<div className="hero-content element-center">
									<div className="hero-content-desc">
										<span className="hero-sub-title mb-20">
											<span className="hero-line"></span>
											we're the best
										</span>
										<h2 className="hero-title">
											UAE &nbsp;  Luggage <br /> transport 
										</h2>
										<p className="mb-40">
										  We provide a unique combination of on demand, time-sensitive, door to-door delivery services within the UAE, We make sure that the passenger’s luggage, is delivered safely and on-time.
										</p>
										<div className="hero-btn">
											<Link to="/contact" className="btn-1 btn-md mr-30">
												Discover More
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Slider --> */}

		{/* <!-- Start About
		============================================= --> */}
		<div className="about-area-2 de-padding">
			<div className="container">
				<div className="about-wpr-2 grid-2">
					<div className="about-left-2">
						<div className="about-left-img">
							<div className="about-phase grid-2">
								<div className="about-phase-1">
									<img src="assets/img/vector/about-2.png" alt="no" className="about-left-img-main mb-30" />
									<img src="assets/img/vector/about-3.png" alt="no" className="about-left-img-main" />
								</div>
								<div className="about-phase-2 element-center">
									<img src="assets/img/vector/about-1.png" alt="no" className="about-left-img-main" />
								</div>
							</div>
							<img src="assets/img/vector/about-shape.png" alt="no" className="about-left-img-shape" />
						</div>
					</div>
					<div className="about-right-2 pl-30">
						<span className="hero-sub-title mb-20">
							<span className="hero-line"></span>
							About Company
						</span>
						<h2 className="heading-1 mb-30">
							Digital &amp; trusted transport logistic company
						</h2>
						<p className="mb-50">
						Al Zayed has been established in year 2000, The pioneer in the lost and found baggage delivery
							company. We provide a unique combination of on demand, time-sensitive, door to-door delivery
							services within the UAE, We make sure that the passenger’s luggage, is delivered safely and
							on-time.
						</p>
						<div className="about-opt-2">
							<div className="about-opt-left-2">
								<p>
									Our Staff
									Completes
									A Project
									Very Quickly.
								</p>
								<Link to="/about" className="btn-3">Read More</Link>
							</div>
							<div className="about-opt-right-2">
								<div className="about-opt-right-box-2 about-opt-right-pd">
									<div className="about-opt-icon-2">
										<i>
											<img src="assets/img/icon/delivered.png" alt="no" />
										</i>
									</div>
									<div className="about-opt-desc-2">
										<h2 className="heading-1">1959+</h2>
										<h5 className="heading-5 mb-0">
											Delivery Successful
										</h5>
									</div>
								</div>
								<div className="about-opt-right-box-2 about-opt-right-pd">
									<div className="about-opt-icon-2">
										<i>
											<img src="assets/img/icon/satisfaction.png" alt="no" />
										</i>
									</div>
									<div className="about-opt-desc-2">
										<h2 className="heading-1">100%</h2>
										<h5 className="heading-5 mb-0">
											Clients Satisfaction
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End About --> */}
		
		{/* <!-- Start Service
		============================================= --> */}
		<div className="service-area-2 bg-2 de-padding pos-rel">
			<img src="assets/img/shape/service-shape.png" className="service-shape-2" alt="no" />
			<div className="container">
				<div className="row">
					<div className="col-xl-8">
						<div className="site-title mb-90">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								0ur services
							</span>
							<h2 className="heading-1 mb-0">
								Specialist logistics <br /> services
							</h2>
						</div>
					</div>
					<div className="col-xl-4">
						<div className="service-res">
							<p className="mb-30">
							Our service gives you the power and flexibility to effectively manage all your deliveries.
							Al Zayed provides its services all around UAE via its land routes associates at prime gateways
							</p>
							<Link to="/contact" className="btn-1 btn-md">
								Discover More
							</Link>
						</div>
						
					</div>
				</div>
				<div className="service-wpr-2 grid-3">
					<div className="service-box-2">
						<div className="service-pic-2">
							<img src="assets/img/pictures/service-1.jpg" alt="no" />
							<div className="service-desc-2">
								<div className="service-icon-title">
									<div className="service-icon-2">
										<i>
											<img src="assets/img/icon/cargo-ship.png" alt="no" />
										</i>
									</div>
									<div className="service-title-2">
										<h5 className="heading-5 mb-0">
											Clients At Abu Dhabi Airport
										</h5>
									</div>
								</div>
								<div className="service-text">
								<p>
									<ul>
										<li>Egypt Airways</li>
										<li>Qatar Airways</li>
									</ul>
									</p>
									<Link to="/client" className="service-btn-2">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="service-box-2">
						<div className="service-pic-2">
							<img src="assets/img/pictures/service-2.jpg" alt="no" />
							<div className="service-desc-2">
								<div className="service-icon-title">
									<div className="service-icon-2">
										<i>
											<img src="assets/img/icon/Icon-plane.png" alt="no" />
										</i>
									</div>
									<div className="service-title-2">
										<h5 className="heading-5 mb-0">
										Clients At Sharjah Airport
										</h5>
									</div>
								</div>
								<div className="service-text">
								<p>
									<ul>
										<li>Turkish	 Airlines</li>
										<li>Qatar Airways</li>
									</ul>
									</p>
									<Link to="/client" className="service-btn-2">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="service-box-2">
						<div className="service-pic-2">
							<img src="assets/img/pictures/service-3.jpg" alt="no" />
							<div className="service-desc-2">
								<div className="service-icon-title">
									<div className="service-icon-2">
										<i>
											<img src="assets/img/icon/Icon-home.png" alt="no" />
										</i>
									</div>
									<div className="service-title-2">
										<h5 className="heading-5 mb-0">
										Clients At Dubai Airport
										</h5>
									</div>
								</div>
								<div className="service-text">
									<p>
									<ul>
										<li>Oman Airways</li>
										<li>British Airways</li>
									</ul>
									</p>
									<Link to="/client" className="service-btn-2">Learn More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Service --> */}
		
		{/* <!-- Start Request
		============================================= --> */}
		{/* <div className="requ-area bg-theme-2 de-pt">
			<div className="requ-wpr">
				<div className="row">
					<div className="col-xl-6">
						<div className="requ-left-pic element-bottom pr-120">
							<img src="assets/img/vector/requ-left.png" alt="no" />
						</div>
					</div>
					<div className="col-xl-6">
						<div className="requ-delivery req-delivery-two">
							<div className="requ-contact requ-contact-white">
								<span className="hero-sub-title mb-20">
									<span className="hero-line"></span>
									CALL TO ACTION
								</span>
								<h2 className="heading-1 mb-40">
									Request a free quote
								</h2>
								<form action="assets/mail/contact.php" method="post" className="contact-form-requ">
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" className="form-control input-style-2" placeholder="Name*" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" className="form-control input-style-2" placeholder="Phone" />
											</div>
										</div>
										<div className="col-md-6">
											<select className="form-control input-style-2 form-select">
												<option>Freight Type</option>
											</select>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" className="form-control input-style-2"  placeholder="City of departure" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" className="form-control input-style-2" placeholder="Delivery City" />
											</div>
										</div>
										<div className="col-md-6">
											<select className="form-control input-style-2 form-select">
												<option>Incoterms</option>
											</select>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<input type="text" className="form-control input-style-2" placeholder="Width" />

											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<input type="text" className="form-control input-style-2"  placeholder="Height" />

											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<input type="text" className="form-control input-style-2"  placeholder="Weight" />

											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group">
												<input type="text" className="form-control input-style-2"  placeholder="Length" />
											</div>
										</div>
										<div className="col-md-12">
											<ul className="destination">
												<li>
													<input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
													<label htmlFor="styled-checkbox-1">Fragile</label>
												</li>
												<li>
													<input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
													<label htmlFor="styled-checkbox-2">Express Delivery</label>
												</li>
												<li>
													<input className="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4" />
													<label htmlFor="styled-checkbox-4">packaging</label>
												</li>
											</ul>
										</div>
										<div className="col-md-12">
											<div className="contact-sub-btn mt-50">
												<button disabled type="submit" className="btn-1 btn-md">
													Request For A Quote
													<i className="fas fa-chevron-right"></i>
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> */}
		{/* <!-- End Request --> */}
		
		{/* <!-- Start Gallery 
		============================================= --> */}
		{/* <div className="gallery-area bg de-padding pos-rel">
			<img src="assets/img/shape/gallery-shape.png" alt="no" className="gallery-shape" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="site-title text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								0ur latest portfolio
							</span>
							<h2 className="heading-1 mb-0">
								Explore recent projects
							</h2>
						</div>
					</div>
				</div>
				<div className="gallery-wpr">
					<div className="swiper port-sldr">
						<div className="swiper-wrapper">
							<div className="swiper-slide">
								<div className="gallery-single">
									<div className="gallery-pic">
										<img src="assets/img/pictures/gallery-1.jpg" alt="no" />
										<div className="gallery-desc">
											<h3 className="heading-3">Ocean Cargo Roundtable</h3>
											<p>
												There are many variations of passages of Lorem Ipsum available There are many variationsThere are
											</p>
											<Link to="#" className="gallery-btn">
												<i className="fa-solid fa-chevron-right"></i>
												See Projects
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="gallery-single">
									<div className="gallery-pic">
										<img src="assets/img/pictures/gallery-2.jpg" alt="no" />
										<div className="gallery-desc">
											<h3 className="heading-3">Ocean Cargo Roundtable</h3>
											<p>
												There are many variations of passages of Lorem Ipsum available There are many variationsThere are
											</p>
											<Link to="#" className="gallery-btn">
												<i className="fa-solid fa-chevron-right"></i>
												See Projects
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="gallery-single">
									<div className="gallery-pic">
										<img src="assets/img/pictures/gallery-3.jpg" alt="no" />
										<div className="gallery-desc">
											<h3 className="heading-3">Ocean Cargo Roundtable</h3>
											<p>
												There are many variations of passages of Lorem Ipsum available There are many variationsThere are
											</p>
											<Link to="#" className="gallery-btn">
												<i className="fa-solid fa-chevron-right"></i>
												See Projects
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="gallery-single">
									<div className="gallery-pic">
										<img src="assets/img/pictures/gallery-4.jpg" alt="no" />
										<div className="gallery-desc">
											<h3 className="heading-3">Ocean Cargo Roundtable</h3>
											<p>
												There are many variations of passages of Lorem Ipsum available There are many variationsThere are
											</p>
											<Link to="#" className="gallery-btn">
												<i className="fa-solid fa-chevron-right"></i>
												See Projects
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="swiper-button-next"></div>
						<div className="swiper-button-prev"></div>
						<div className="swiper-pagination"></div>
					</div>
				</div>
			</div>
		</div> */}
		{/* <!-- End Gallery --> */}
		
		{/* <!-- Start Review
		============================================= --> */}
		<div className="testi-area bg-3 de-padding pos-rel">
			<img src="assets/img/vector/train-amico.png" alt="no" className="train-shape" />
			<div className="container">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="site-title text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								Mission
							</span>
							<h2 className="heading-1 mb-0">
							Excellence in Luggage  Recovery
							</h2>
						</div>
					</div>
				</div>
				<div className="testi-wpr">
					{/* <div className="row">
						<div className="col-xl-6 offset-xl-3">
							<div className="testi-up text-center">
								<div thumbsslider="" className="swiper testi-sldr">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src="assets/img/pictures/80x80-1.png" alt="no" />
										</div>
										<div className="swiper-slide">
											<img src="assets/img/pictures/80x80-2.png" alt="no" />
										</div>
										<div className="swiper-slide">
											<img src="assets/img/pictures/80x80-3.png" alt="no" />
										</div>
										<div className="swiper-slide">
											<img src="assets/img/pictures/80x80-4.png" alt="no" />
										</div>
										<div className="swiper-slide">
											<img src="assets/img/pictures/80x80-5.png" alt="no" />
										</div>
										<div className="swiper-slide">
											<img src="assets/img/pictures/80x80-6.png" alt="no" />
										</div>
									</div>
								  </div>
							</div>
						</div>
					</div> */}
					<div className="swiper testi-sldr-2">
						<div className="swiper-wrapper">
							<div className="swiper-slide">
								<div className="testi-single">
									<p>
									Our mission is to be the premier choice for lost and found luggage delivery solutions, offering unparalleled service and a customer-centric approach. We are dedicated to ensuring complete satisfaction through our commitment to excellence, reliability, and efficiency.

We strive to build lasting relationships with our clients by thoroughly understanding their business, assessing their current needs, and anticipating future requirements. By sharing their vision, we collaborate in developing and achieving their goals. Integrity stands at the core of our values, guiding every aspect of our operations.
									</p>
									<div className="testi-star-bio">
										<div className="testi-star">
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
										</div>
										<div className="testi-bio">
											<h4>Muhammad Jawad</h4>
											<span>CEO Transport Ltd. </span>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="swiper-slide">
								<div className="testi-single">
									<p>
										There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
									</p>
									<div className="testi-star-bio">
										<div className="testi-star">
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
										</div>
										<div className="testi-bio">
											<h4>Fidel Edwards</h4>
											<span>CEO Transport Ltd. </span>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="testi-single">
									<p>
										There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
									</p>
									<div className="testi-star-bio">
										<div className="testi-star">
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
										</div>
										<div className="testi-bio">
											<h4>Hamish Steveson</h4>
											<span>CEO Transport Ltd. </span>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="testi-single">
									<p>
										There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
									</p>
									<div className="testi-star-bio">
										<div className="testi-star">
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
										</div>
										<div className="testi-bio">
											<h4>Lui Maguire</h4>
											<span>CEO Transport Ltd. </span>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="testi-single">
									<p>
										There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
									</p>
									<div className="testi-star-bio">
										<div className="testi-star">
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
										</div>
										<div className="testi-bio">
											<h4>Tei Junk Seok</h4>
											<span>CEO Transport Ltd. </span>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="testi-single">
									<p>
										There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
									</p>
									<div className="testi-star-bio">
										<div className="testi-star">
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
											<i className="fa-solid fa-star"></i>
										</div>
										<div className="testi-bio">
											<h4>Samura Koshin</h4>
											<span>CEO Transport Ltd. </span>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					 </div>
				</div>
			</div>
		</div>
		{/* <!-- End Review --> */}
		
		{/* <!-- Start Counter
		============================================= --> */}
		<div className="counter-area de-padding pos-rel hero-bg" style={{backgroundImage: `url("assets/img/bg/bg-counter.jpg")`}}>
			<div className="container">
				<div className="counter-wpr">
					<div className="row align-items-center">
						<div className="col-xl-4">
							<div className="counter-title">
								<span className="hero-sub-title mb-20">
									<span className="hero-line"></span>
									Number Talks
								</span>
								<h2 className="heading-2 mb-30">
									Providing assistance since 2000
								</h2>
								<Link to="/about" className="btn-5 btn-md">Get Our Story</Link>
							</div>
						</div>
						<div className="col-xl-8">
							<div className="counter-1 grid-3">
								<div className="fun-fact">
									<div className="counter-icon">
										<i><img src="assets/img/icon/counter-1.png" alt="no" /></i>
									</div>
                                    <div className="counter">
                                        <div className=""></div>
                                        <div className="operator">98%</div>
                                    </div>
                                    <span className="medium">Business Hike</span>
                                </div>
								<div className="fun-fact">
									<div className="counter-icon">
										<i><img src="assets/img/icon/counter-2.png" alt="no" /></i>
									</div>
                                    <div className="counter">
                                        <div className=""></div>
                                        <div className="operator">788K</div>
                                    </div>
                                    <span className="medium">Sales of our Products</span>
                                </div>
								<div className="fun-fact">
									<div className="counter-icon">
										<i><img src="assets/img/icon/counter-3.png" alt="no" /></i>
									</div>
                                    <div className="counter">
                                        <div className=""></div>
                                        <div className="operator">150M</div>
                                    </div>
                                    <span className="medium">Happy clients</span>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Counter --> */}
		

		{/* <!-- Start Step
		============================================= --> */}
		{/* <div className="step-area de-padding">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="site-title text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								How it work
							</span>
							<h2 className="heading-1">
								We follow great process
							</h2>
							<p className="mb-0">
							We offer a fully integrated service from a fully committed and equipped team, capable of meeting
every related need. Regardless of your requirement, our approach remains the same. Our first priority is to gain
a thorough understanding of the requirements of your business and then efficiently dealing with each task as
they present themselves. A dedicated team led by the directors to services all clients.
							</p>
						</div>
					</div>
				</div>
				<div className="step-wpr grid-4">
					<div className="step-box">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/dispatch-note.png" className="step-note-icon" alt="no" />
									<span className="step-num">01.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-1.png" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Dispatched</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
					<div className="step-box mt-50">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/delivery-truck.png" className="step-note-icon" alt="no" />
									<span className="step-num">02.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-2.png" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Dispatched</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
					<div className="step-box">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/cost-per-click.png" className="step-note-icon" alt="no" />
									<span className="step-num">03.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-1.png" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Delivered</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
					<div className="step-box mt-50">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/pay.png" className="step-note-icon" alt="no" />
									<span className="step-num">04.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-1.png" className="opacity-0" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Payment</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
				</div>
			</div>
		</div> */}
		{/* <!-- End Step --> */}

		{/* <!-- Start Team
		============================================= --> */}
		<div className="team-area de-padding">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="site-title text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								Our Team
							</span>
							<h2 className="heading-1 mb-20">
								Our experienced experts
							</h2>
							<p className="mb-0">
								
							For the last 20 years almost , we are offering the best luggage
							delivery services with the help of our professional drivers.
							Al Zayed has employed 8 regular visa holders, which is the highest
							number as compared with other competitors.
							</p>
						</div>
					</div>
				</div>
				<div className="team-wpr grid-3">
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr.Talha Athar</h4>
							<span>Truck driver</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-4.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr. Athar Hussain</h4>
							<span>Manager at Dubai Airport</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-3.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
					<div className="team-box">
						<div className="team-bio">
							<h4>Mr. Naseem Baig</h4>
							<span>Manager at Sharjah Airport</span>
						</div>
						<div className="team-pic">
							<img src="assets/img/pictures/team-4.png" className="team-main" alt="no" />
							<img src="assets/img/shape/team-shape.png" className="team-shape" alt="no" />
							<img src="assets/img/shape/team-shape-2.png" className="team-shape-2" alt="no" />
							<ul className="team-social">
								<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
								<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
								<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
								<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Team --> */}
		
	</main>	
    )

}
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

import getUrl from "../constants/urlConst";

import "./index.css";

export default function Tracking(){
	const [formData, setFormData] = useState({
		trackingNumber: "",
		mobileNumber: "",
	});
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		// ✅ Check if at least one field has a value
		if (!formData.trackingNumber && !formData.mobileNumber) {
			setError("Please enter either a tracking number or a mobile number.");
			setLoading(false);
			return;
		}
		
		try {
			const res = await axios.post(getUrl() + "luggage/track", formData);
			const data = res.data;
			if (!data.success) {
				setError(data.errors);
				setResponse(null);
				return;
			} else {
				setResponse(data.response);
				setError(null);
			}
			
		} catch (err) {
			setError("Failed to track shipment. Please try again.");
		} finally {
			setLoading(false);
		}
	};

    return(
		<main className="main">
			{/* <!-- Start Breadcrumb
			============================================= --> */}
			<div className="site-breadcrumb" style={{backgroundImage: `url("assets/img/pictures/breadcrumb.jpg")`}}>
				<div className="container">
					<div className="site-breadcrumb-wpr">
						<h2 className="breadcrumb-title">Tracking</h2>
						<ul className="breadcrumb-menu clearfix">
						<li><Link to="/">Home</Link></li>
							<li className="active">Tracking</li>
						</ul>
					</div>
				</div>
			</div>
			{/* <!-- End Breadcrumb --> */}
			<div className="checkout-area bg de-padding">
				<div className="container">
					<div className="checkout-wrapper pos-rel">
						<form className="checkout" onSubmit={handleSubmit}>
							<h2>
								<i className="fas fa-shipping-fast"></i> Track Your Shipment
							</h2>
							<div className="street">
								<label htmlFor="trackingNumber">Tracking number</label>
								<input 
									type="text" 
									name="trackingNumber" 
									value={formData.trackingNumber} 
									onChange={handleChange} 
								/>
							</div>
							<div className="street">
								<label htmlFor="mobileNumber">Mobile Number</label>
								<input 
									type="text" 
									name="mobileNumber" 
									value={formData.mobileNumber} 
									onChange={handleChange} 
								/>
							</div>
							<div className="btns">
								<button type="submit" disabled={loading}>
									{loading ? "Tracking..." : "Track Now"}
								</button>
							</div>
							{error && <p style={{ color: "white" }}>{error}</p>}
						</form>
					</div>
				</div>
			</div>

			{/* <!-- Start Step ============================================= --> */}
			{response && 
				<div className="step-area de-padding">
					<div className="container">
						<div class="row">
							<div class="col-xl-8 offset-xl-2">
								<div class="site-title text-center">
									<span class="hero-sub-title mb-20">
										<span class="hero-line"></span>
										Luggage Details
									</span>
									<p class="mb-0">
										Carrier Name : {response.carrierName} <br />
										{ response.carrierPhoneNumber && <span>Carrier Contact : {response.carrierPhoneNumber} <br /></span> }
										{ response.recipientName && <span>Recipient Name : {response.recipientName} <br /></span> }
										{ response.recipientPhoneNumber && <span>Recipient Contact : {response.recipientPhoneNumber} <br /></span> }
									</p>
								</div>
							</div>
						</div>
						<div className="step-wpr grid-3">
							{/* Step 1: Dispatched */}
							<div className="step-box">
								<div className="step-icon">
									<div className="step-elm">
										<div className="step-elm-elm">
											<img src="assets/img/shape/Shape.png" alt="default" />
											<img src="assets/img/icon/dispatch-note.png" className="step-note-icon" alt="dispatched" />
											<span className="step-num">01.</span>
										</div>
										<div className="step-wavy-arrow">
											<img src="assets/img/shape/wavy-arrow-1.png" alt="arrow" />
										</div>
									</div>
								</div>
								<div className="step-desc">
									<h4 className="heading-4">Order Dispatched</h4>
									<p className="mb-0">Your luggage has left the origin.</p>
								</div>
							</div>

							{/* Step 2: In Transit */}
							<div className={`step-box mt-50 ${response.luggageStatus === 'Dispatched' ? 'inactive' : ''}`}>
								<div className="step-icon">
									<div className="step-elm">
										<div className="step-elm-elm">
											<img src="assets/img/shape/Shape.png" alt="default" />
											<img src="assets/img/icon/delivery-truck.png" className="step-note-icon" alt="in-transit" />
											<span className="step-num">02.</span>
										</div>
										<div className="step-wavy-arrow">
											<img src="assets/img/shape/wavy-arrow-2.png" alt="arrow" />
										</div>
									</div>
								</div>
								<div className="step-desc">
									<h4 className="heading-4">Order In Transit</h4>
									<p className="mb-0">Your luggage is on its way.</p>
								</div>
							</div>

							{/* Step 3: Delivered */}
							<div className={`step-box ${response.luggageStatus === 'Dispatched' || response.luggageStatus === 'InTransit' ? 'inactive' : ''}`}>
								<div className="step-icon">
									<div className="step-elm">
										<div className="step-elm-elm">
											<img src="assets/img/shape/Shape.png" alt="default" />
											<img src="assets/img/icon/cost-per-click.png" className="step-note-icon" alt="delivered" />
											<span className="step-num">03.</span>
										</div>
									</div>
								</div>
								<div className="step-desc">
									<h4 className="heading-4">Order Delivered</h4>
									<p className="mb-0">Your luggage has been successfully delivered.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			{/* <!-- End Step --> */}
		</main>	
    )
}
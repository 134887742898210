import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
    const [mobileNav, setMobileNav] = useState(false);
    return (
        <header className="header header-before-off">
            <div className="main-wrapper">
                <div className="navbar navbar-expand-lg bsnav bsnav-sticky bsnav-sticky-slide bsnav-transparent">
                    <img src="assets/img/bg/bg-nav.png" alt="no" className="navbar-bar-shape" />
                    <div className="navbar-container">
                        <div className="navbar-extra-logo">
                            <Link to='/'>
                                <img src="assets/img/logo/logo-white.png" className="logo-outside" alt="thumb" />
                            </Link>
                        </div>
                        <div className="top-header-menu">
                            <div className="top-bar-area pos-rel topbar-white">
                                <img src="assets/img/bg/topbar-bg.png" alt="no" className="top-bar-shape" />
                                <div className="row">
                                    <div className="col-xl-10 col-lg-6">
                                        <div className="top-box-wrp d-flex justify-content-center align-items-center">
                                            <div className="top-box top-location hm-2">
                                                <span> Welcome to AL ZAYED LUGGAGE DELIVERY LLC</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-6">
                                        <div className="top-bar-social">
                                            <ul className="top-social">
                                                <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                                <li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="navbar-menu-opt">
                                <div className="navbar-brand-tog">
                                    <Link className="navbar-brand g-nop" to='/'>
                                        <img src="assets/img/logo/logo-white.png" className="logo-display" alt="thumb" />
                                        <img src="assets/img/logo/logo.png" className="logo-scrolled" alt="thumb" />
                                    </Link>
                                    <button onClick={() => setMobileNav((preValue) => !preValue)} className={mobileNav ? "navbar-toggler toggler-spring active" : "navbar-toggler toggler-spring"}>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                                <div className="collapse navbar-collapse justify-content-md-between">
                                    <Link className="navbar-brand nop" to="/">
                                        <img src="assets/img/logo/logo-white.png" className="logo-display" alt="thumb" />
                                        <img src="assets/img/logo/logo.png" className="logo-scrolled" alt="thumb" />
                                    </Link>
                                    <ul className="navbar-nav navbar-mobile justify-content-md-center w-100">
                                        <li className="nav-item dropdown fadeup"><Link className="nav-link" to='/'>Home </Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/about">About Us</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/client">Clients</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/tracking">Tracking</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/contact">Contact</Link></li>
                                    </ul>
                                    <div className="search-cart nav-profile">
                                        <Link to="/contact" className="btn-1 btn-sm">Let's Begin</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={mobileNav ? "bsnav-mobile in" : "bsnav-mobile"}>
                    <div className="bsnav-mobile-overlay" onClick={() => setMobileNav((preValue) => !preValue)}></div>
                    <div className="navbar">
                        <ul className="navbar-nav navbar-mobile justify-content-md-center w-100">
							<li className="nav-item fadeup"><Link className="nav-link" to='/'>Home </Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/about">About Us</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/client">Client</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/tracking">Tracking</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/contact">Contact</Link></li>
						</ul>
                    </div>
                </div>
            </div>
        </header>
    );
}
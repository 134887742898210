import { Link } from "react-router-dom";

export default function Tracking(){
    return(
<main className="main">
        
		{/* <!-- Start Breadcrumb
		============================================= --> */}
		<div className="site-breadcrumb" style={{backgroundImage: `url("assets/img/pictures/breadcrumb.jpg")`}}>
			<div className="container">
				<div className="site-breadcrumb-wpr">
					<h2 className="breadcrumb-title">Tracking</h2>
					<ul className="breadcrumb-menu clearfix">
                    <li><Link to="/">Home</Link></li>
						<li className="active">Tracking</li>
					</ul>
				</div>
			</div>
		</div>
        {/* <!-- End Breadcrumb --> */}
        
        <div className="checkout-area bg de-padding">
        	<div className="container">
				<div className="checkout-wrapper pos-rel">
					<form className="checkout">
						<h2>
							<i className="fas fa-shipping-fast"></i>
							Track Your Shipment
						</h2>
						<div className="street">
							<label htmlFor="name">Tracking number</label>
							<input type="email" name="email" />
						</div>
						<div className="street">
							<label htmlFor="name">Mobile Number</label>
							<input type="text" name="address" />
						</div>
						<div className="btns">
							<button disabled>Track Now</button>
						</div>
					</form>
				</div>
			</div>
        </div>
		
		
	</main>	
    )
}
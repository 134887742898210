import { Link } from "react-router-dom";

export default function Client(){
    return(
            <main className="main">
        
		{/* <!-- Start Breadcrumb
		============================================= --> */}
		<div className="site-breadcrumb" style={{backgroundImage: `url("assets/img/pictures/breadcrumb.jpg")`}}>
			<div className="container">
				<div className="site-breadcrumb-wpr">
					<h2 className="breadcrumb-title">Our Clients</h2>
					<ul className="breadcrumb-menu clearfix">
                    <li><Link to="/">Home</Link></li>
						<li className="active">Clients</li>
					</ul>
				</div>
			</div>
		</div>
        {/* <!-- End Breadcrumb --> */}
        
		
        {/* <!-- Start Price
		============================================= --> */}
		<div className="price-area de-padding">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
                                Clients At Dubai Airport
							</span>
							<ul className="list-unstyled d-flex flex-wrap justify-content-center">
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i> Oman Airways</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>British Airways</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Turkish Airlines</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Thai Airways</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Royal Jordanian Airways</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Singapore Airlines</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Qatar Airways</span>
                                </li>
                                <li className="d-flex align-items-center mx-3 my-2">
                                    <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Virgin Atlantis</span>
                                </li>
                            </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Price --> */}

		{/* <!-- Start Counter
		============================================= --> */}
		<div className="counter-area de-padding pos-rel hero-bg" style={{backgroundImage: `url("assets/img/bg/bg-counter.jpg")`}}>
			<div className="container">
				<div className="counter-wpr">
					<div className="row align-items-center">
						<div className="col-xl-4">
							<div className="counter-title">
								<span className="hero-sub-title mb-20">
									<span className="hero-line"></span>
									Clients At Sharjah Airport
								</span>
								<ul className="list-unstyled d-flex flex-wrap justify-content-center">
                                    <li className="d-flex align-items-center mx-3 my-2">
                                        <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i> Oman Airways</span>
                                    </li>
                                    <li className="d-flex align-items-center mx-3 my-2">
                                        <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Qatar Airways</span>
                                    </li>
                                    <li className="d-flex align-items-center mx-3 my-2">
                                        <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Turkish Airlines</span>
                                    </li>
                                    <li className="d-flex align-items-center mx-3 my-2">
                                        <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Air Arabia</span>
                                    </li>
                                </ul>
							</div>
						</div>
						<div className="col-xl-8">
							<div className="counter-1">
								<div className="counter-title">
								<span className="hero-sub-title mb-20">
									<span className="hero-line"></span>
									Clients At Ras al khaima Airport
								</span>
								<ul className="list-unstyled d-flex flex-wrap">
                                    <li className="d-flex align-items-center mx-3 my-2">
                                        <span className="px-3 py-2"> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Air Arabia</span>
                                    </li>
                                </ul>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Counter --> */}
		
		
		
		{/* <!-- Start Review
		============================================= --> */}
		<div className="review-area bg de-padding pos-rel">
			<img src="assets/img/vector/big-car.png" className="big-car" alt="no" />
			<div className="container">
				<div className="review-wpr grid-2">
					<div className="review-left">
						<span className="hero-sub-title mb-20">
							<span className="hero-line"></span>
							Clients At Abu Dhabi Airport
						</span>
						<ul className="list-unstyled d-flex flex-wrap justify-content-center">
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i> Oman Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Egypt Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Qatar Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Royal Jordanian Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Royal Dutch Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Air India</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Gulf Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Kuwait Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Pegasus Airlines</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>British Airways</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>SriLankan Airlines</span>
                            </li>
                            <li className="d-flex align-items-center mx-3 my-2">
                                <span className="px-3 py-2" style={{color: 'black'}}> <i className="fas fa-plane" style={{marginRight: '5px'}}></i>Air France</span>
                            </li>
                        </ul>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Review --> */}
		
	</main>	
    )
}
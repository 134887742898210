// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from ".//components/Banner";
import Upload from ".//components/Upload";

// Assets
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/avatar3.png";
import React from "react";

export default function Overview() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea={{ base: "1 / 1", md: "1 / 1" }}
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        />
        <Upload
          gridArea={{ base: "2 / 1", md: "1 / 2" }} // On small screens, upload goes below banner
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        />
      </Grid>
    </Box>
  );
}

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import ColumnsTable from ".//components/ColumnsTable";
import tableDataColumns from ".//variables/tableDataColumns.json";
import React from "react";

export default function Settings() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='30px'
        spacing={{ base: "20px", xl: "20px" }}>
        <ColumnsTable
          tableData={tableDataColumns}
        />
      </SimpleGrid>
    </Box>
  );
}

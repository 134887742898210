import { useState, useEffect } from "react";

import Logout from "./shared/Logout.js";
import {jwtDecode} from "jwt-decode";
import Auth from "../layouts/auth/index.js";
import Dashboard from "../layouts/admin/index.js";
import { encryptStorage } from "../constants/EncryptStorage.js";

const tokenString = encryptStorage.getItem("token");
let localToken = "";
if (tokenString !== undefined) localToken = tokenString;

const LoginRedirect = () => {
  const [values, setValues] = useState({ token: "" });
  const [isTokenExist, setIsTokenExist] = useState(false);
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      token: localToken,
    }));
    setIsTokenExist(true);
  }, []);

  if (!isTokenExist) {
    return null;
  }

  if (!values.token) {
    return <Auth setValues={setValues} />;
  }

  if (values.token) {
    let token = jwtDecode(values.token);
    let tokenTime = new Date(token.exp * 1000)
    tokenTime.setMinutes(tokenTime.getMinutes() + 1);
    const currentTime = new Date();
    if (tokenTime < currentTime) {
      return <Logout />;
    }
  }

  return <Dashboard />;
}

export default LoginRedirect;

import { Link } from "react-router-dom";

export default function Footer(){
    return (
        <footer className="footer pos-rel overflow-hidden hero-bg" style={{backgroundImage: `url("assets/img/bg/bg-footer.jpg")`}}>
			<div className="footer-up de-padding">
				<div className="container">
					<div className="row g-5">
						<div className="col-xl-4 col-lg-6 col-md-6">
							<div className="footer-widget about-us">
								<div className="footer-logo mb-30">
									<img src="assets/img/logo/logo-white.png" alt="no" />
								</div>
								<p className="mb-40">
								We provide a unique combination of on demand, time-sensitive, door to-door delivery
									services within the UAE, We make sure that the passenger’s luggage, is delivered safely and
									on-time.
								</p>
								<div className="footer-bio">
									<div className="footer-bio-pic">
										<img src="assets/img/pictures/footer-bio.jpg" alt="no" />
									</div>
									<div className="footer-bio-text">
										<h6>Mr. Athar Hussain</h6>
										<span>alzayedld@gmail.com</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-lg-6 col-md-6">
							<div className="footer-widget footer-link">
								<h4 className="footer-widget-title">
									Useful Links
									<span className="footer-title-line"></span>
								</h4>
								<ul className="footer-list">
									<li>
										<Link to="/about">
											<i className="fa-solid fa-chevron-right"></i>
											About Us 
										</Link>
									</li>
									<li>
										<Link to="/contact">
											<i className="fa-solid fa-chevron-right"></i>
											Contact us
										</Link>
									</li>
									<li>
										<Link to="/tracking">
											<i className="fa-solid fa-chevron-right"></i>
											Tracking
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-6">
							<div className="footer-widget pr-30">
								<h4 className="footer-widget-title">
									Working Hours
									<span className="footer-title-line"></span>
								</h4>
								<p className="mb-30">
									<b>24/7 Luggage Service Available</b><br />
									We provide reliable luggage service around the clock, every day of the week.
									<br />Available Monday to Sunday, 24 hours a day, 7 days a week.
								</p>
								{/* <ul className="footer-hours">
									<li><span>Saturday :</span> <span>8.00 - 16.00</span> </li>
									<li><span>Sun - Wed :</span> <span>9.00 - 16.00</span> </li>
									<li><span>Thursday :</span> <span>10.00 - 16.30</span> </li>
									<li><span>Friday :</span> <span>Closed</span></li>
								</ul> */}
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-md-6">
							<div className="footer-widget">
								<h4 className="footer-widget-title">
									Newsletter
									<span className="footer-title-line"></span>
								</h4>
								<p className="mb-30">
									Subscribe our newsletter to get our latest update &amp;
									news
								</p>
								<div className="subscribe-area">
									<form>
										<span className="btn-shape"></span>
										<input type="text" className="input-style-4" placeholder="Enter Email..." />
										<button type="submit" className="btn-subs">
											<i className="fa-solid fa-chevron-right"></i>
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright py-5">
				<div className="container">
					<div className="copyright-element d-flex align-items-center justify-content-between">
						<p className="mb-0">Copyright 2025 AL ZAYED LUGGAGE DELIVERY LLC . All Rights Reserved By Zayed Luggage</p>
						<ul className="footer-social">
							<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
							<li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
							<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
							<li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
    )
}
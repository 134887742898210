import { Link } from "react-router-dom";

export default function Contact(){
    return (
        <main className="main">
        
		{/* <!-- Start Breadcrumb
		============================================= --> */}
		<div className="site-breadcrumb" style={{backgroundImage: `url("assets/img/pictures/breadcrumb.jpg")`}}>
			<div className="container">
				<div className="site-breadcrumb-wpr">
					<h2 className="breadcrumb-title">Contact Us</h2>
					<ul className="breadcrumb-menu clearfix">
						<li><Link to="/">Home</Link></li>
						<li className="active">Contact Us</li>
					</ul>
				</div>
			</div>
		</div>
        {/* <!-- End Breadcrumb --> */}
        
      	{/* <!-- Start Contact us
		============================================= --> */}
		<div className="contact-area de-padding">
			<div className="container">
				<div className="contact-wpr">
					<div className="row g-5">
						<div className="col-xl-4">
							<div className="contact-sdebar">
								<div className="contact-up-title">
									<h2 className="heading-1">
										Get in Touch
									</h2>
									<p className="mb-0">
									 Looking for reliable luggage transport in the UAE? Contact us today for a quote or more details on our services!
									</p>
								</div>
								<div className="addr-home">
									<div className="addr-box">
										<div className="addr-box-single">
											<div className="addr-icon">
												<i className="icofont-google-map"></i>
											</div>
											<div className="addr-desc">
												<h5>Location</h5>
												<p className="mb-0">
													P.O.Box: 84776,  Dubai - UAE <br /> United Arab Emirates
												</p>
											</div>
										</div>
										<div className="addr-box-single">
											<div className="addr-icon">
												<i className="icofont-phone"></i>
											</div>
											<div className="addr-desc">
												<h5>Make a Call</h5>
												<p className="mb-0">
													<b>Mr. Athar Hussain : </b>+971-589-163-200<br />
													<b>Muhammad Asif : </b>+971-545-159-0589<br />
													<b>Mr. Talha Athar : </b>+971-558-660-247 
												</p>
											</div>
										</div>
										<div className="addr-box-single">
											<div className="addr-icon">
												<i className="icofont-email"></i>
											</div>
											<div className="addr-desc">
												<h5>Our Email</h5>
												<p className="mb-0">
													alzayedld@gmail.com
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8">
							<div className="contact-home pl-30">
								<form action="assets/mail/contact.php" method="post" className="contact-form contact-cnt">
									<div className="contact-form-title mb-30">
										<h2 className="heading-1">
											Send us a message
										</h2>
										<p className="mb-0">
										Need luggage transport in the UAE? Message us for a quote or details!
										</p>
									</div>
									<div className="row">
										<div className="col-xl-6">
											<div className="col-md-12">
												<div className="form-group">
													<input type="text" className="form-control input-style-2" id="name" name="name" placeholder="Your Full Name*" />
													<span className="alert alert-error"></span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<input type="email" className="form-control input-style-2" id="email" name="email" placeholder="Your Email Address*" />
													<span className="alert alert-error"></span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<input type="text" className="form-control input-style-2" id="phone" name="phone" placeholder="Phone Number" />
													<span className="alert alert-error"></span>
												</div>
											</div>
										</div>
										<div className="col-xl-6">
											<div className="col-md-12">
												<textarea className="form-control input-style-2" id="comment" name="comments" placeholder="Your Message..."></textarea>
												{/* <!-- Alert Message --> */}
												<div className="alert-notification">
													<div id="message" className="alert-msg"></div>
												</div>
											</div>
										</div>
									</div>
									<div className="sub-btn d-flex align-items-center justify-content-between">
										<div className="form-check">
											<input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
											<label className="form-check-label" htmlFor="flexCheckDefault">
												I agree to the <span>Terms &amp; Conditions</span> 
											</label>
										</div>
										<div className="contact-sub-btn">
											<button disabled type="submit" name="submit" id="submit" className="btn-submit">
												Send Message 
												<i className="fas fa-chevron-right"></i>
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Contact us --> */}
		
		{/* <!-- Start Google Map
		============================================= --> */}
		<div className="g-map-area">
			<div className="g-map--wrapper text-center">
            <iframe title="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14527.99987630306!2d54.632700559661544!3d24.450786383620528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e48de900c71ef%3A0x9b379979236d755e!2sZayed%20International%20Airport!5e0!3m2!1sen!2s!4v1737049725596!5m2!1sen!2s" width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</div>
		{/* <!-- End Google Map --> */}
		
	</main>	
    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .step-box.active {
    border: 2px solid #4CAF50;
    background-color: #e8f5e9;
}

.step-box.active .step-num {
    background-color: #4CAF50;
    color: white;
} */

.step-box.inactive {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
}

.step-box.inactive .step-num {
    background-color: #ccc;
    color: #666;
}

.step-box.inactive img {
    filter: grayscale(100%);
}`, "",{"version":3,"sources":["webpack://./src/components/tracking/index.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH;IACI,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["/* .step-box.active {\r\n    border: 2px solid #4CAF50;\r\n    background-color: #e8f5e9;\r\n}\r\n\r\n.step-box.active .step-num {\r\n    background-color: #4CAF50;\r\n    color: white;\r\n} */\r\n\r\n.step-box.inactive {\r\n    opacity: 0.5;\r\n    filter: grayscale(100%);\r\n    pointer-events: none;\r\n}\r\n\r\n.step-box.inactive .step-num {\r\n    background-color: #ccc;\r\n    color: #666;\r\n}\r\n\r\n.step-box.inactive img {\r\n    filter: grayscale(100%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

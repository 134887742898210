import { encryptStorage } from "../../constants/EncryptStorage";

function Logout() {

  localStorage.clear();
  encryptStorage.clear();

  window.location.href = "/";
  
  return null;
}

export default Logout;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  @keyframes blink {
    0% { background-color: #007bff; }
    50% { background-color: #ff5722; } /* Change to an attention-grabbing color */
    100% { background-color: #007bff; }
  }
  
  .floating-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #007bff;
    color: white;
    padding: 15px 20px;
    border-radius: 50px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
    animation: blink 1s infinite alternate; /* Blink effect */
    z-index: 1000;
  }
  
  .floating-button:hover {
    transform: scale(1.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/floatingButton/index.css"],"names":[],"mappings":";;EAEE;IACE,KAAK,yBAAyB,EAAE;IAChC,MAAM,yBAAyB,EAAE,EAAE,0CAA0C;IAC7E,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,2CAA2C;IAC3C,+BAA+B;IAC/B,sCAAsC,EAAE,iBAAiB;IACzD,aAAa;EACf;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":["\r\n\r\n  @keyframes blink {\r\n    0% { background-color: #007bff; }\r\n    50% { background-color: #ff5722; } /* Change to an attention-grabbing color */\r\n    100% { background-color: #007bff; }\r\n  }\r\n  \r\n  .floating-button {\r\n    position: fixed;\r\n    bottom: 20px;\r\n    left: 20px;\r\n    background-color: #007bff;\r\n    color: white;\r\n    padding: 15px 20px;\r\n    border-radius: 50px;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);\r\n    transition: transform 0.2s ease;\r\n    animation: blink 1s infinite alternate; /* Blink effect */\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .floating-button:hover {\r\n    transform: scale(1.1);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdPowerSettingsNew
  // MdLock,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from './/views/admin/default';
import Profile from './/views/admin/profile';
import DataTables from './/views/admin/dataTables';
import Logout from './/views/shared/Logout';

// Auth Imports
// import SignInCentered from './/views/auth/signIn';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: '/admin/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Data Tables',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/admin/data-tables',
    component: <DataTables />,
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: '/admin/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
  {
    name: 'Log out',
    layout: '/admin',
    path: '/admin/logout',
    icon: <Icon as={MdPowerSettingsNew} width="20px" height="20px" color="inherit" />,
    component: <Logout />,
  },
];

export default routes;

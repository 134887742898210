import './assets/css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import LoginRedirect from './views/LoginRedirect';
// Chakra imports

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Router>
        <Routes>
          <Route path="*" element={<LoginRedirect />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
